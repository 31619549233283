import React from "react"
import { Link } from "gatsby"

const SiteTitle = ({ title, desc }) => (
  <Link to="/" className="site-title">
    <h1>{title}</h1>
    <h2>{desc}</h2>
  </Link>
)

export default SiteTitle
