import React from "react"
import { FaGithubSquare, FaTwitterSquare } from "react-icons/fa"
import SocialMediaLink from "./social-media-link"

const SocialMediaLinks = () => (
  <div className="social-media-links-container">
    <SocialMediaLink
      url="https://github.com/LeHaine"
      icon={<FaGithubSquare size={42} color="#FFF" />}
    />
    <SocialMediaLink
      url="https://twitter.com/_LeHaine"
      icon={<FaTwitterSquare size={42} color="#1DA1F2" />}
    />
  </div>
)

export default SocialMediaLinks
